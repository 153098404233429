<template>
  <div class="container">
    <b-field label="Onderwerp">
      <b-input v-model="question.topic" />
    </b-field>
    <b-field label="Jaar">
      <b-select v-model="question.year">
        <option v-for="n in 10" :value="n">{{ n }}</option>
      </b-select>
    </b-field>
    <b-field label="Niveau">
      <b-select v-model="question.level">
        <option v-for="level in educationLevels" :value="level">{{ level | capitalize }}</option>
      </b-select>
    </b-field>
    <b-field label="Text">
      <b-input v-model="question.text" />
    </b-field>
    <b-field label="Vraag">
      <b-input v-model="question.question" />
    </b-field>
    <b-field label="Prefix">
      <b-input v-model="question.prefix" />
    </b-field>
    <b-button type="is-primary" @click="create()">Opslaan</b-button>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import router from "@/router";

export default {
  name: "create",
  components: {},
  data() {
    return {
      question: {
        topic: "",
        year: 1,
        level: "vwo",
        text: "",
        question: "",
        prefix: "",
      }
    };
  },
  mounted() { },
  methods: {
    async create() {
      let response = await ApiService.post("math", this.question);
    },
  },
  computed: {
    educationLevels() {
      return this.$store.state.app.educationLevels
    }
  },
};
</script>